import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CommentIcon from "@mui/icons-material/Comment";
import React, { useEffect, useState } from "react";
import { Alert, Card, Form, ToggleButton } from "react-bootstrap";
import SignaturePad from "signature_pad";
import { LiveHelpOutlined } from "@mui/icons-material";
import axios from "axios";
import { complianceAnswerArray } from "../TempCompliance_answer";

const SubQuestion4option = ({ questions,q,question, changeAnswer, answerReport,index,reportId, key, indexA, totalQuestion }) => {
  const [hint, setHint] = useState(false);
  const [showRisk, setShowRisk] = useState(false);
  const [comment, setComment] = useState("");
  const [image, setImage] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [description, setDescription] = useState(false);
  const [score, setScore] = useState(0);
  const [text, setText] = useState("");
  const [long, setLong] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [continueAnswer, setContinueAnswer] = useState([])
  const [getQuestionId, setQuestionId] = useState()

  
  const fetchAnswerArray = ()=>{
    if(reportId){
      // axios.get("https://www.api.safeinschool.in/admin/compliance_answer/1026")
      axios.get(process.env.REACT_APP_API_BASE_URL+"admin/compliance_answer/"+reportId)
    .then((res)=>{
      // setContinueAnswer(res.data.result.answer);
      console.log(complianceAnswerArray, "complianceAnswerArray value subquestion4 option ")
      setContinueAnswer(complianceAnswerArray);
    }).catch((error)=>{
      console.log(error.message);
    })
    }else{
      setContinueAnswer(answerReport)
    }
  }

  const fetchAnswer = ()=>{
    let answer ={}
      answer = continueAnswer.find((ans)=>{
        return ans.questionId === question.id;    
    })
    if(answer){
      setLong(answer.text)
      setText(answer.text)
      setSelectedCheckboxes([answer.text])
    }
  }
  useEffect(()=>{
    fetchAnswer()
  },[continueAnswer])

  useEffect(()=>{
    if(reportId){
      fetchAnswerArray();
    }
  },[reportId])
  // useEffect(() => {
  //   setScore(0);
  //   setText("");
  //   setComment("");
  //   setImage("");
  //   setShowComment(false);
  //   setAnchorEl(null);

  // }, [question]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    // Handle option selection here
    // console.log(option);
    handleMenuClose();
  };
  const getSignature = (data) => {
  };

  const handleAnswerSelection = (questionId,  option,text, comment, image,index) => {

    let isSubQuestion = null;
   setQuestionId(questionId)
    if (option.sub_question && index >= 0) {
      isSubQuestion = index;
    }

    let max = 0;
    const riskArray = question.options.filter((i) => {
      return i.risk === true;
    });
    let riskFlag = false;
    riskArray.forEach((i) => {
      if (i.text === option.text) {
        setShowRisk(i.riskText);
        riskFlag = true;
      }
      if (!riskFlag) {
        setShowRisk(null);
      }
    });

    let maxScore = question.options.find((o) => {
      if (max <= o.score) {
        max = o.score;
      }

      return Number(max);
    });
    if (!maxScore) {
      maxScore = question.options[0];
    }
    if (isSubQuestion >= 0 && isSubQuestion != null) {
      changeAnswer(
        question.categoryId,
        questionId,
        option.score,
        Number(maxScore.score),
        comment,
        image,
        index,
        true,
       text,
        index
      );
    } else {

      changeAnswer(
        question.categoryId,
        questionId,
        option.score,
        Number(maxScore.score),
        comment,
        image,
        index,
        false,
        text,
        null
      );
    }
  }
  const handleAnswerSelectOption = (questionId, option, comment, image, signature,text) => {
    // setanswersetLoading(true)
        let max = 0;
        const riskArray = question.options.filter((i) => {
       
          return i.risk === true;
        });
        let riskFlag = false;
        riskArray.forEach((i) => {
          if (i.text === option.text) {
            setShowRisk(i.riskText);
            riskFlag = true;
          }
          if (!riskFlag) {
            setShowRisk(null);
          }
        });
        let maxScore = question.options.find((o) => {
          if (max < o.score) {
            max = o.score;
          }
          return Number(max);
        });
        if (!maxScore) {
          maxScore = question.options[0];
        }
        console.log("text===>",text)
    
        changeAnswer(question.categoryId, questionId, option.score, Number(maxScore.score), comment, image, index, signature,text);
      };

  return (
    <>

    <Card className={`mb-2 rounded-0 shadow-hover-sm transition border`} style={{ background:"#d5dbe999"}}>
      <Card.Body style={{}}>
        <div className="d-flex align-items-start justify-content-between">
          <div>
            {/* <small className="">
              Question {index + 1}({String.fromCharCode(97 + indexA)}) of {totalQuestion}
              of {totalQuestion}
            </small> */}
            <br />
            <div dangerouslySetInnerHTML={{ __html: question.text }} />

            {question.description && (
              <Tooltip title="Description" placement="top">
                <IconButton className="text-primary" onClick={() => setDescription(!description)}>
                  <LiveHelpOutlined />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className="d-flex align-items-start">
            {question.hint && (
              <Tooltip title="Hint" placement="top">
                <IconButton onClick={() => setHint(!hint)}>
                  <LiveHelpIcon />
                </IconButton>
              </Tooltip>
            )}
            <div>
              {question.images_required && (
                <Tooltip title="Add Photo" placement="top">
                  <IconButton onClick={handleMenuOpen}>
                    <AddPhotoAlternateIcon />
                  </IconButton>
                </Tooltip>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={{
                  sx: {
                    padding: "8px 12px",
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
                {/* {!showLink && <MenuItem onClick={(e) => handleOptionSelect(e)}>Link</MenuItem>} */}
                {
                  <div>
                    <input className="form-control shadow-none rounded-0 p-2" value={image} onChange={(e) => setImage(e.target.value)} type="url" placeholder="Paste your link here" />
                    <button className="btn-sm btn mt-2 border border-2 border-dark rounded-0" onClick={() => handleMenuClose()}>
                      Upload
                    </button>
                  </div>
                }
              </Menu>
            </div>
            {question.comment_required && (
              <Tooltip title="Add Comment" placement="top">
                <IconButton>
                  <CommentIcon onClick={() => setShowComment(!showComment)} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </div>
        {/* Description, Hint and RIsk  */}
        <div>
          {/* Description */}
          {description && question.description && (
            <div>
              <p>{question.description}</p>
            </div>
          )}

          {/* Hint */}
          {hint && question.hint && (
            <Alert variant="success" className="mb-1 border border-success p-1 px-2 d-inline-block fw-semibold text-success bg-white rounded-0 me-2">
              <small>HINT: {question.hint}</small>
            </Alert>
          )}
          {/* Risk*/}
          {showRisk && (
            <Alert className="mb-1 border border-danger p-1 px-2 d-inline-block fw-semibold text-danger bg-white rounded-0 me-2">
              <small> RISK: {showRisk}</small>
            </Alert>
          )}
        </div>

        <div className="">
          {question.question_type === "YesNo" || question.question_type === "YesNONa" || question.question_type === "mcq" || question.question_type === "CheckBox" ? (
            <div className="d-flex">
              {question.options.length > 0 &&
                question.options.map((option, index) => (
                  <div key={option.id} className="mb-1">
                    <ToggleButton
                      className="me-2 text-capitalize border border-2 border-primary px-4 py-2"
                      id={`option-${question.id}index-${index}`}
                      type={question.question_type === "CheckBox" ? "checkbox" : "radio"}
                      variant="outline-primary"
                      name={`option-${question.id}`}
                      value={option.score}
                      checked={question.question_type === "CheckBox" ? selectedCheckboxes.includes(option.text) : text === option.text}
                      onClick={() => {
                        if (question.question_type === "CheckBox") {
                          const updatedCheckboxes = [...selectedCheckboxes];

                          if (updatedCheckboxes.includes(option.text)) {
                            const indexToRemove = updatedCheckboxes.indexOf(option.text);
                            updatedCheckboxes.splice(indexToRemove, 1);
                          } else {
                            updatedCheckboxes.push(option.text);
                          }
                          // console.log("inside the question", updatedCheckboxes)

                          setSelectedCheckboxes(updatedCheckboxes);
                        }
                        handleAnswerSelection(question.id,  option,option.text, comment, image,index,);
                        setScore(option.score);
                        setText(option.text);
                      }}>
                      {option.text}
                    </ToggleButton>
                  </div>
                ))}
            </div>
          ) : null}

          {question.question_type === "Number" && (
            <div className="">
              <input
                className="text-align-center form-control"
                style={{
                  textAlign: "center",
                  width: "220px",
                }}
                type="number"
                // value={number}
                onChange={(e) => {
                  // setNumber(parseInt(e.target.value));
                  // handleAnswerSelection(
                  //   question.id,
                  //   question.options[0],
                  //   comment,
                  //   image
                  // );
                }}
              />
              <div className="mt-4">
                {showComment && (
                  <Form.Control
                    // value={comment}
                    onChange={(e) => {
                      // setComment(e.target.value);
                      // handleAnswerSelection(question.id, score, text, e.target.value, image, index);
                    }}
                    as="textarea"
                    rows={1}
                  />
                )}
              </div>
            </div>
          )}

          {(question.question_type === "Long" || question.question_type === "Short") && (
            <div className="">
              <textarea
                className="text-align-center form-control"
                // style={{
                //   textAlign: "center",
                //   width: "220px",
                // }}
                // type="number"
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                  handleAnswerSelection(question.id, question.options[0],text, comment, image,index,);
                  // handleAnswerSelection(question.id, question.options[0], comment, image,text);

                }}
              />
              <div>
                {showComment && (
                  <Form.Control
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                      handleAnswerSelection(question.id, score, text, e.target.value, image, index);
                    }}
                    as="textarea"
                    rows={1}
                  />
                )}
              </div>
            </div>
          )}
          {question.question_type === "Signature" && (
            <div className=" ">
              <SignaturePad getSignature={getSignature} user={true} />
              <div>
                {showComment && (
                  <Form.Control
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value);
                      handleAnswerSelection(question.id, score, text, e.target.value, image, index);
                    }}
                    as="textarea"
                    rows={1}
                  />
                )}
              </div>
            </div>
          )}
          {question.question_type === "Dropdown"  ? (<>
          
          <select
            className="form-control"
            id="questionType"
            value={text}
          onChange={(e)=>{
              setText(e.target.value)
            handleAnswerSelectOption(question.id, question.options[0], comment, image,null,e.target.value);

                // setScore(option.score);
                }}
          >
            <option selected disabled>Select option</option>
            {question.options.length > 0 &&
              question.options.map((data, index) =>
                <option value={data.text} key = {index}>{data.text}</option>)}

          </select>
  </>) : null}
          <div>
            {showComment && (
              <Form.Control
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                  handleAnswerSelection(question.subQuestion4optionId, score, text, e.target.value, image);
                }}
                as="textarea"
                rows={1}
              />
            )}
          </div>
        </div>
     
      </Card.Body>
    </Card>
    {    questions.filter((subQ) => subQ.question_id === question.id && subQ.option_text === text && subQ?.categoryId === subQ?.categoryId).map((i, subIndex) => {
                              let count = 0;
                              return (
                                <div>
                                  <SubQuestion4option questions={questions} index={index} q={q} answerReport={answerReport} indexA={indexA} question={i} reportId={reportId} changeAnswer={changeAnswer} totalQuestion={totalQuestion} />
                                </div>
                              )
                            })}
    </>
    
  );
};

export default SubQuestion4option;
