export const complianceQuestionArray = [
  //_________________________________________
  //_______________Energy Audit__________________
  //_____________________________________________

  {
    id: 5,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What are the various source(s) of energy used in school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Electricity",
        score: 1,
        correct: false,
        riskText: "",
        sub_question: {
          sub_options: [
            {
              text: "Electricity (from Municipality/Electrical Company)",
            },
            {
              text: "Electricity from generator",
              sub_question: {
                sub_options: [
                  {
                    text: "If a generator is used, specify the fuel used",
                    details: [
                      {
                        text: "Amount of Fuel used in a month",
                      },
                      {
                        text: "Generator Capacity",
                      },
                      {
                        text: "Frequency of use of the generator",
                        sub_options: [
                          {
                            text: "Only in an emergency (in case of electricity supply failure)",
                          },
                          {
                            text: "Regularly (Specify purposes and duration)",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
      },
      {
        risk: false,
        text: "Gas",
        score: 2,
        correct: false,
        riskText: "",
        sub_question: {
          sub_options: [
            {
              text: "Gas cylinders",
            },
            {
              text: "Piped National Gas (PNG)",
            },
          ],
        },
      },
    ],
    hint: "",
    createdAt: "2023-09-04T12:16:52.000Z",
    updatedAt: "2024-05-16T10:05:55.000Z",
  },

  {
    id: 6,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have any alternative sources of energy generation? If yes, indicate the alternate source.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 7,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school get bills for consumption of electricity and other energy sources?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-04T12:36:51.000Z",
    updatedAt: "2023-09-04T12:36:51.000Z",
  },
  {
    id: 8,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Average consumption per person",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "01",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:28:12.000Z",
    updatedAt: "2023-09-05T04:28:12.000Z",
  },
  {
    id: 9,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Air-Conditioned School",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 10,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "For Non-Air Conditioned School",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 12,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Light Sources",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 13,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Energy Conservation Practices of school",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 14,
    complianceId: 3,
    categoryId: 4,
    category: "Energy Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Green practices",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  //______________________________________________________
  //__________________Biodiversity Audit__________________
  //______________________________________________________

  {
    id: 15,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have a natural green ambience? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 16,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Each plant, animal and micro organism contributes to the diversity of an area. Which one of the following productive areas are present in your school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 17,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are there adequate spaces with plants and trees besides lawns in sufficient numbers in the school campus? (For eg. Rows of trees etc.)",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 18,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Do you find a greater number of native and indigenous plants/ trees non-native plants/ trees in the school campus? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 19,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How many native trees are there in the school campus? (Native trees are indigenous to a given region or ecosystem). Specify number.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "None (no trees on the campus)",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Less than 5-10 trees per acre of area",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "About 5-10 trees per acre of area ",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "More than 10 trees per acre of area ",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 20,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How many non-native trees are in the school campus?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "None (no trees on the campus)",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No non-native tress, only indigenous tress",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Less than 5-10 trees per acre of area",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "About 5-10 trees per acre of area",
        score: 4,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "More than 10 trees per acre of area (Discuss)",
        score: 5,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 21,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Approximately what percentage of the passages, paths and pavements in the school are made of concrete?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "25%",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "50%-75%",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "75-100% ",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Nearly All",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 22,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Do you see weeds in your school campus? If yes, how frequently?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "mcq",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Very frequently",
        score: 1,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Rarely",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Seldom",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Never",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 23,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What procedure is adopted to remove weeds?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Manual removal of weeds",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Use of pesticides/herbicides",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other (specify)",
        score: 3,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 24,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What type of manure/fertilizes are used for plants?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Only Compost (prepared at school)",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Mostly Chemical fertilizers",
        score: "2",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Organic compost purchased",
        score: 3,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 25,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does the school make of use indigenous methods (washing/ neem cake/ oil/ bio enzymes) to tackle pests?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 26,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is green waste composted in your school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 27,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is there a possibility/ scope for converting rooftops and other concrete/barren spaces into green areas?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 28,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is there a possibility to replace open concrete surfaces with permeable pavements for percolation of rain water into the ground?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 29,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your schools organize any talk/interactive session to emphasize interdependence of living creatures and encourage values and virtues of cooperation and cohabitation? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 30,
    complianceId: 3,
    categoryId: 5,
    category: "Biodiversity Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: ". Do the school authorities/ personnel prefer to gift potted plants/ saplings/ cuttings to guests, visitor and awardees? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  //__________________________________________________________________
  //_________________________Water Audit______________________________
  //__________________________________________________________________

  {
    id: 31,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have one source of water? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 32,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What source/s does your school draw water from Municipal water",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 33,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school use the same source of water for drinking and sanitation?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 34,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What process/procedure has your school adopted to make water potable (fit for drinking)?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 35,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is drinking water tested for potability in accredited labs?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 36,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the total water consumption of the school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 37,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have a water conservation policy? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 38,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "If YES, then what water conservation practices does your school adopt?",
    sub_question: [
      {
        id: 38,
        option_text:
          "Do majority of taps in your school have fixtures that minimize loss of water (low flow taps, self-closing taps, use of sensors, timer shut off devices, use of low regulators etc.)?",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
      {
        id: 38,
        option_text:
          "Do you have dual flush system in your school toilets to minimize loss of water?",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
      {
        id: 38,
        option_text:
          "Are float-valves fixed in various water tanks to avoid water wastage?",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
      {
        id: 38,
        option_text:
          "Is there a mechanism for grey water recycling (reuse of water used for washing, AC water) in your school?",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
      {
        id: 38,
        option_text: "Is rain-water harvesting done in the school?",
        options: [
          { value: "yes", label: "Yes" },
          { value: "no", label: "No" },
        ],
      },
    ],
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "mcq",
    media_type: "",
    images: null,
    options: [],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 39,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How quickly are leaking taps of toilets/overhead tanks etc. repaired?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Promptly",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "In a day or So",
        score: 2,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 40,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are the lids of the water tanks secure? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 41,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How often are storage tanks cleaned?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Once a year",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Twice a year",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Quarterly",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other periodicity?",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 42,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the approximate area of lawns with green grass in your school that requires regular watering? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 43,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: " Which of the following methods of irrigation is used for watering of grass and plants in the school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Manual watering using a garden hose pipe",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Sprinkler system with long range sprinklers",
        score: "2",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Sprinkler system with short range sprinklers",
        score: "3",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Drip irrigation system",
        score: "4",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other type of system",
        score: 5,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 44,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What system does the school use to harvest rainwater?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Direct storage",
        score: "1",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Rain-water harvesting tanks",
        score: "2",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Direct storage to raw water storage tanks",
        score: "3",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Recharge into the ground aquifer",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 45,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are unpaved/green surfaces available in school for percolation of rain water? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 46,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the capacity of rain water storage tanks? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 47,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How many tanks/structures and their locations? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 48,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How is the water harvested utilized? Internal recycling in school campus applications such as- ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Gardening",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Toilets",
        score: "2",
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Cleaning of school",
        score: 3,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 49,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is Ground water recharged through trenches",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 50,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are awareness campaigns conducted for conservation of water in your school? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 51,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are gardeners,cooks,safaisevaks and others in your school aware of the need and ways to conserve water?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 52,
    complianceId: 3,
    categoryId: 6,
    category: "Water Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are water conservation stickers/signs/postersfixed in bathrooms, canteens, classrooms and other places to remind students of good practices?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  //______________________________________________________________________________
  //______________________________Waste Audit_____________________________________
  //______________________________________________________________________________

  {
    id: 53,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Campus Survey: Is your school campus litter free? (Litter: Used items such as paper, cans, andbottles left lying in an open or public place)",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 54,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "As you walk in the schoolcampus do you find dustbins at all places/regular intervals?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 55,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are there different dustbins for different types of waste? q Yes q No If yes, specify how are they identified? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 56,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Tick the categories into which waste is segregated into your school:",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Dry waste",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Wet waste (Biodegradable)",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "E-waste",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Hazardous waste from the Iabs",
        score: 4,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Chemical waste",
        score: 5,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Paints",
        score: 6,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Sanitary waste",
        score: 7,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Garden waste",
        score: 8,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Miscellaneous",
        score: 9,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 57,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is dry waste segregated in your school? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 58,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Kitchen/Canteen waste",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 59,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How frequently are dust/waste bins emptied?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Daily",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "Two times a day",
        score: 2,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Weekly",
        score: 3,
        correct: false,
        riskText: "",
      },
      {
        risk: false,
        text: "Any other",
        score: 4,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 60,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "How does your school dispose-off dry waste :-",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 61,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Is composting an organized activity and encouraged for students and staff in your school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 62,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school advocate and provide information to reduce waste in school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 63,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Are posters/slogans placed at various points in school to remind students of proper ways of waste disposal?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 64,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Paper waste:- Please tick mark the practices which your school encourages for encouraging the reduction in use of paper: ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 65,
    complianceId: 3,
    categoryId: 7,
    category: "Waste Audit",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: " Is there a student appreciation/recognition reward system for observing efficient waste management practices? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  //________________________________________________________________________________
  //________________________Transport_______________________________________________
  //________________________________________________________________________________

  {
    id: 66,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the total strength of the school?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 67,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Write the approximate percentage of the school students who travel to and from school by various modes oftransportation.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 68,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Write the approximate percentage of the school teachers and other staff who travel to and from the school by various modes of transportation.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 69,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does your school have transport facility (buses/ cars/ vans/ matadors etc.) available for students and staff? ",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 70,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "If the school has its own transport vehicles:",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
  {
    id: 71,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "What is the number of buses and other vehicles that school owns and the fuels used by them? (You would get thisinformation through the school transport in-charge/school office records)",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 72,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Calculate the quantity of fuel consumed and the cost of different fuels used by yourschool transport permonth.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 73,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Calculate the per person consumption of fuel and cost by dividing the liters consumed/amountspent by number of persons in the school.",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },

  {
    id: 74,
    complianceId: 3,
    categoryId: 8,
    category: "Transport",
    desc_modal: [
      {
        img: "",
        description: "",
      },
    ],
    text: "Does the school organize any programs to encourage the use of public transport, carpooling etc and to sensitize regarding the minimum use of non-renewable fuels?",
    sub_question: null,
    images_required: false,
    image_max: null,
    file_max: null,
    required: false,
    comment_required: false,
    files_required: false,
    description:
      "",
    question_type: "YesNo",
    media_type: "",
    images: null,
    options: [
      {
        risk: false,
        text: "Yes",
        score: "1",
        correct: true,
        riskText: "",
      },
      {
        risk: false,
        text: "No",
        score: 0,
        correct: false,
        riskText: "",
      },
    ],
    hint: "",
    createdAt: "2023-09-05T04:30:56.000Z",
    updatedAt: "2023-09-05T04:30:56.000Z",
  },
];
