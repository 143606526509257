import { useFormik } from "formik";
import "./EnrolmentForm.css";
import React from "react";

const EnrolmentForm = ({ FormVisibleDisable }) => {
  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      NameOfTheSchool: "",
      AddressOfTheSchool: "",
      typeOfSchool: "",
      nameOfThePrincipal: "",
      Name: "",
      Designation: "",
      Mobile: "",
      Email: "",
      schoolAffiliationCode: "",
      PrimaryStudent: "",
      MiddleStudent: "",
      SeniorStudent: "",
      FoodBusiness: "",
      provided: "",
    },
    onSubmit: (value) => {
      const keys = Object.keys(value);

      if (
        keys.map((key) => value[key]).some((fields) => fields?.trim() === "")
      ) {
        alert("Please fill all the required fields");
        return;
      }

      localStorage.setItem("formSubmitted", "true");
      FormVisibleDisable();
    },
  });

  return (
    <div className="mainDiv">
      <div className="innerDiv">
        <div>
          <p className="EnrolmentTagP">Enrolment Form</p>
          <p className="generalTag">General Information</p>
        </div>

        <div>
          <form onSubmit={handleSubmit}>
            <ul className="formUl">
              {/* Name of the School */}
              <li>
                <div className="inputBox">
                  <label htmlFor="NameOfTheSchool" className="inputLabel">
                    Name of the School
                  </label>
                  <input
                    type="text"
                    id="NameOfTheSchool"
                    name="NameOfTheSchool"
                    className="inputField"
                    onChange={handleChange}
                    value={values.NameOfTheSchool}
                  />
                </div>
              </li>

              {/* Address of School */}
              <li>
                <div className="inputBox">
                  <label htmlFor="AddressOfTheSchool" className="inputLabel">
                    Address of School{" "}
                  </label>
                  <input
                    type="text"
                    id="AddressOfTheSchool"
                    name="AddressOfTheSchool"
                    className="inputField"
                    onChange={handleChange}
                    value={values.AddressOfTheSchool}
                  />
                </div>
              </li>

              {/* Type of School */}
              <li>
                <div>
                  <p className="inputLabel">
                    Type of School – Government/ Private/ Govt funded
                  </p>

                  <div className="inputRadioBox">
                    <div className="inputRadio">
                      <label htmlFor="Primary">Primary</label>
                      <input
                        type="radio"
                        name="typeOfSchool"
                        id="Primary"
                        value="Primary" // Explicitly set value
                        onChange={handleChange}
                        checked={values.typeOfSchool === "Primary"}
                      />
                    </div>
                    <div className="inputRadio">
                      <label htmlFor="Middle">Middle</label>
                      <input
                        type="radio"
                        name="typeOfSchool"
                        id="Middle"
                        value="Middle" // Explicitly set value
                        onChange={handleChange}
                        checked={values.typeOfSchool === "Middle"}
                      />
                    </div>
                    <div className="inputRadio">
                      <label htmlFor="Senior">Senior</label>
                      <input
                        type="radio"
                        name="typeOfSchool"
                        id="Senior"
                        value="Senior" // Explicitly set value
                        onChange={handleChange}
                        checked={values.typeOfSchool === "Senior"}
                      />
                    </div>
                    <div className="inputRadio">
                      <label htmlFor="Residential">Residential</label>
                      <input
                        type="radio"
                        name="typeOfSchool"
                        id="Residential"
                        value="Residential" // Explicitly set value
                        onChange={handleChange}
                        checked={values.typeOfSchool === "Residential"}
                      />
                    </div>
                    <div className="inputRadio">
                      <label htmlFor="Day-boarding">Day-boarding</label>
                      <input
                        type="radio"
                        name="typeOfSchool"
                        id="Day-boarding"
                        value="Day-boarding" // Explicitly set value
                        onChange={handleChange}
                        checked={values.typeOfSchool === "Day-boarding"}
                      />
                    </div>
                  </div>
                </div>
              </li>

              {/* Name of the Principal */}
              <li>
                <div className="inputBox">
                  <label htmlFor="nameOfThePrincipal" className="inputLabel">
                    Name of the Principal{" "}
                  </label>
                  <input
                    type="text"
                    id="nameOfThePrincipal"
                    name="nameOfThePrincipal"
                    className="inputField"
                    onChange={handleChange}
                    value={values.nameOfThePrincipal}
                  />
                </div>
              </li>

              {/* Nodal Contact Details */}
              <li>
                <div>
                  <p className="inputLabel">Nodal Contact Details</p>

                  <div>
                    {/* nodal upper box */}
                    <div className="NodalContactBox">
                      {/* Nodal Contact Details Name */}
                      <div className="inputBox nodalInputBox">
                        <label htmlFor="Name" className="inputLabel">
                          Name
                        </label>
                        <input
                          type="text"
                          id="Name"
                          name="Name"
                          className="inputField"
                          onChange={handleChange}
                          value={values.Name}
                        />
                      </div>

                      {/* Nodal Contact Details Designation */}
                      <div className="inputBox nodalInputBox">
                        <label htmlFor="Designation" className="inputLabel">
                          Designation
                        </label>
                        <input
                          type="text"
                          id="Designation"
                          name="Designation"
                          className="inputField"
                          onChange={handleChange}
                          value={values.Designation}
                        />
                      </div>
                    </div>

                    {/* nodal lower box */}
                    <div className="NodalContactBox">
                      {/* Nodal Contact Details Mobile */}
                      <div className="inputBox nodalInputBox">
                        <label htmlFor="Mobile" className="inputLabel">
                          Mobile
                        </label>
                        <input
                          type="text"
                          id="Mobile"
                          name="Mobile"
                          className="inputField"
                          onChange={handleChange}
                          value={values.Mobile}
                        />
                      </div>

                      {/* Nodal Contact Details Email */}
                      <div className="inputBox nodalInputBox">
                        <label htmlFor="Email" className="inputLabel">
                          Email
                        </label>
                        <input
                          type="text"
                          id="Email"
                          name="Email"
                          className="inputField"
                          onChange={handleChange}
                          value={values.Email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              {/* School Affiliation Code */}
              <li>
                <div className="inputBox">
                  <label htmlFor="schoolAffiliationCode" className="inputLabel">
                    School Affiliation Code{" "}
                  </label>
                  <input
                    type="text"
                    id="schoolAffiliationCode"
                    name="schoolAffiliationCode"
                    className="inputField"
                    onChange={handleChange}
                    value={values.schoolAffiliationCode}
                  />
                </div>
              </li>

              {/* Student Strength */}
              <li>
                <div className="inputBox">
                  <label className="inputLabel">Student Strength</label>

                  <div className="inputBox StudentStrength">
                    <label htmlFor="PrimaryStudent" className="inputLabel">
                      Primary
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="PrimaryStudent"
                      id="PrimaryStudent"
                      onChange={handleChange}
                      value={values.PrimaryStudent}
                    />
                  </div>
                  <div className="inputBox StudentStrength">
                    <label htmlFor="MiddleStudent" className="inputLabel">
                      Middle
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="MiddleStudent"
                      id="MiddleStudent"
                      onChange={handleChange}
                      value={values.MiddleStudent}
                    />
                  </div>
                  <div className="inputBox StudentStrength">
                    <label htmlFor="SeniorStudent" className="inputLabel">
                      Senior
                    </label>
                    <input
                      type="text"
                      className="inputField"
                      name="SeniorStudent"
                      id="SeniorStudent"
                      onChange={handleChange}
                      value={values.SeniorStudent}
                    />
                  </div>
                </div>
              </li>

              {/* Food Business Operator */}
              <li>
                <div>
                  <p className="inputLabel">Food Business Operator</p>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      <div>
                        <input
                          type="radio"
                          name="FoodBusiness"
                          id="Canteen"
                          value="Canteen"
                          onChange={handleChange}
                          checked={values.FoodBusiness === "Canteen"}
                        />
                        <label htmlFor="Canteen">
                          Canteen/Food Shop/Vendors/Kiosk
                        </label>
                      </div>
                    </li>

                    <li>
                      <div>
                        <input
                          type="radio"
                          name="FoodBusiness"
                          id="Kitchen"
                          value="Kitchen"
                          onChange={handleChange}
                          checked={values.FoodBusiness === "Kitchen"}
                        />
                        <label htmlFor="Kitchen">Kitchen/Mess</label>
                      </div>
                    </li>

                    <li>
                      <div>
                        <input
                          type="radio"
                          name="FoodBusiness"
                          id="None"
                          value="None"
                          onChange={handleChange}
                          checked={values.FoodBusiness === "None"}
                        />
                        <label htmlFor="None">None</label>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Mid-day Meal */}
              <li>
                <div>
                  <p className="inputLabel">Mid-day Meal</p>
                  <div className="inputBoxProvided">
                    <p>Provided</p>
                    <div className="inputBox">
                      <div>
                        <label htmlFor="yes">Yes</label>
                        <input
                          type="radio"
                          name="provided"
                          id="yes"
                          value="Yes"
                          onChange={handleChange}
                          checked={values.provided === "Yes"}
                        />
                      </div>
                      <div>
                        <label htmlFor="no">No</label>
                        <input
                          type="radio"
                          name="provided"
                          id="no"
                          value="No"
                          onChange={handleChange}
                          checked={values.provided === "No"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <div className="btnBox">
                <button className="submitBtn" type="submit">
                  submit
                </button>
              </div>
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnrolmentForm;
